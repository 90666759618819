<template>
     <transition name="fade">
      <overlay-frosted v-show="show">
         <!-- <close-button @click="$emit('close')" v-if="!unclosable"/> -->
          <div class="vh-100 d-flex flex-column align-items-center 
          justify-content-center "
            
          >
      
                <div class="rounded-extra flex-grow-1 overflow-hidden px-3 d-flex 
                    slide-from-above" 
                    :class="rootClass"
                >
                    <div     v-click-outside="clickedOutside"
                    :class="divClass+' '+bgClass+' '+(selfFlow?'':'overflow-auto')" 
                    class=" main-div mx-auto my-auto"
                         style="max-width:640px;box-shadow:0 0 22px rgba(0,0,0,0.3);height:499px;position: absolute;left: 50%;
    transform: translateX(-50%);top: 0;"
                    >
                        <slot/>
                     </div>
                </div>
           
          </div>
      </overlay-frosted>
       </transition>
</template>

<script>
import OverlayFrosted from './OverlayFrosted'
import ClickOutside from 'vue-click-outside'
// import CloseButton from './CloseButton'
export default {
    components:{
        OverlayFrosted,
        // CloseButton,
    },
    directives:{ClickOutside},
    props:{
        show:{default:true},
        message:{default:'Are you sure?'},
        unclosable:{type:Boolean,default:false},
        divClass:{default:`org-form mh-100 rounded-extra d-flex flex-column`},
        bgClass:{default:'bg-white'},
        title:{type:String,default:``},
        rootClass:{type:String,default:`d-flex flex-column `},
        selfFlow:{type:Boolean,default:false},
    },
    methods:{
        clickedOutside(){
            if(!this.unclosable){
                if(this.show)
                {
                    this.$emit('close')
                }
            }
        }
    }

}
</script>

<style>

 
/*
@media screen and (max-width: 797px){
    .main-div{
        max-width:100vw;
    }
}
@media screen and (min-width: 798px){
    .main-div{
        max-width:80vw;
    }
}
@media screen and (min-width: 992px){
    .main-div{
        max-width:70vw;
    }
}
@media screen and (min-width: 1192px){
    .main-div{
        max-width:60vw;
    }
}
*/
</style>